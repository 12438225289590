<template>
    <vx-card :title="`Create New Receive`">
        <div class="mb-6 vx-row">
            <vs-button @click="handleBack()" class="mt-2 ml-4" color="danger" icon-pack="feather"
                icon="icon-arrow-left">Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Warehouse Code</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="this.form.warehouse.code + ' - ' + this.form.warehouse.name" disabled
                            :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Supplier</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="this.form.supplier_code + ' - ' + this.form.supplier_name" disabled
                            :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Purchase Data</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="this.form.po_code" disabled :readonly="true"
                            class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>GR Document Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input value="auto generated" disabled :readonly="true"
                            class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Posting Date</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <datepicker format="yyyy-MM-dd" name="date" :inline="false"
                            v-model="form.date" placeholder="Select Date" :cleared="() => {
                                this.form.date = null
                            }">
                        </datepicker>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Delivery Note Number*</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input v-model="form.delivery_order_number" class="w-full" />
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Delivery Note Date</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <datepicker format="yyyy-MM-dd" name="date" :inline="false" v-model="form.dn_date"
                            placeholder="Select Date" :cleared="() => {
                                this.form.dn_date = null
                            }">
                        </datepicker>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Note</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-textarea v-model="form.notes" width="100%" />
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <!-- <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Shipment Number*</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input v-model="form.shipment_number" class="w-full" />
                    </div>
                </div> -->
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Attachment</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <div class="w-full vx-col sm:w-5/6 upload">
                            <!-- <vs-button v-if="attachment && attachment.length > 0" class="mr-2 up btnx " type="filled"
                                color="primary" icon-pack="feather" icon="icon-plus"
                                @click="$refs.attachment.click()">Upload</vs-button>
                            <vs-button v-else class="mr-2" color="primary" icon-pack="feather" icon="icon-plus"
                                @click="$refs.attachment.click()">Upload</vs-button>
                            <vs-dropdown v-if="attachment && attachment.length > 0">
                                <vs-button class="up btn-drop" type="filled" icon="expand_more"></vs-button>
                                <vs-dropdown-menu>
                                <vs-dropdown-item>Selected files to be uploaded:</vs-dropdown-item>
                                    <vs-dropdown-item @click="handleDeleteFile(index)"
                                        v-for="(item, index) in attachment" :key="index">
                                        <vs-icon icon="delete" color="danger"></vs-icon>{{ item.name }}
                                    </vs-dropdown-item>
                                </vs-dropdown-menu>
                            </vs-dropdown> -->
                            <input type="file"  
                                ref="attachment" 
                                accept=".jpg,.jpeg,.png,.pdf"
                                @change="handleChangeAttachment"/>
                            <vs-button class="ml-5" color="primary" @click="handleUpload()" type="filled">Upload Attachment</vs-button>   
                            <!-- <vs-button class="ml-5" v-if="form.attachment && form.attachment.length > 0"
                                @click="popShow = true" color="primary" type="filled">Download</vs-button> -->
                            <!-- <vs-popup classContent="popup-example" title="Download or delete" :active.sync="popShow">
                                <vs-list>
                                    <vs-list-item v-for="(item, index) in form.attachment" :key="index"
                                        :title="item.name" class="flex">
                                        <div class="flex">
                                            <a :href="item.url"><vs-button color="success" type="flat"
                                                    icon="file_download"></vs-button></a>
                                            <vs-button color="danger" @click="handleDeleteAttachment(item.id)"
                                                type="flat" icon="delete"></vs-button>
                                        </div>
                                    </vs-list-item>
                                </vs-list>
                            </vs-popup> -->
                        </div>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <vs-divider>
                        List Attachment
                    </vs-divider>
                    <vs-list>
                        <vs-list-item v-for="(item, index) in form.attachment" :key="index"
                            :title="item.name" class="flex">
                            <div class="flex">
                                <a @click.stop="downloadFileAwsS3(item.path)"><vs-button color="success" type="flat"
                                        icon="file_download"></vs-button></a>
                                <vs-button color="danger" @click="handleDeleteAttachment(index, item.id)"
                                    type="flat" icon="delete"></vs-button>
                            </div>
                        </vs-list-item>
                    </vs-list>
                </div>
            </div>
        </div>
        <div class="mb-6 vx-row">
            <div class="w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th colspan="5">Po Data</th>
                            <th colspan="2">GR Data</th>
                            <th>Check Data (Batch/ED)</th>
                        </tr>
                        <tr>
                            <th width="8%">
                                <div>Supplier</div>
                                <div>SKU Code</div>
                            </th>
                            <th width="8%">
                                <div>Internal</div>
                                <div>SKU Code</div>
                            </th>
                            <th width="20%">
                                <div>Internal</div>
                                <div>SKU Description</div>
                            </th>
                            <th width="5%">HU</th>
                            <th width="5%">Qty</th>
                            <th width="5%">HU</th>
                            <th width="5%">Qty</th>
                            <th>Expired Date</th>
                            <th>Batch</th>
                            <th>Check Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in form.lines_dto" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="item.po_item_unit.sku_code_supplier" :readonly="true" disabled
                                    v-if="index == 0 || form.lines_dto[index].purchase_order_line_id != form.lines_dto[index - 1].purchase_order_line_id"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="item.po_item_unit.sku_code" :readonly="true" disabled
                                    v-if="index == 0 || form.lines_dto[index].purchase_order_line_id != form.lines_dto[index - 1].purchase_order_line_id"
                                    class="w-full bg-grey-light" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="item.po_item_unit.item_name" :readonly="true" disabled
                                    v-if="index == 0 || form.lines_dto[index].purchase_order_line_id != form.lines_dto[index - 1].purchase_order_line_id"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="item.po_item_unit.name" :readonly="true" disabled
                                    v-if="index == 0 || form.lines_dto[index].purchase_order_line_id != form.lines_dto[index - 1].purchase_order_line_id"
                                    class="w-full bg-grey-light" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="item.po_quantity" :readonly="true" disabled
                                    v-if="index == 0 || form.lines_dto[index].purchase_order_line_id != form.lines_dto[index - 1].purchase_order_line_id"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <CustomMSelect :value="item.item_unit"
                                    :base-url="`/api/wms/v1/master/item-unit/related/${item.po_item_unit_id}`"
                                    label="name" track-by="name" @selected="(v) => {
                                        item.item_unit = v;
                                        item.item_unit_id = v.id
                                    }"></CustomMSelect>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input @keypress="isNumber($event)" type="number" min="0" v-model="item.quantity" class="w-full" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <!-- <datepicker format="yyyy-MM-dd" name="date" :inline="false"
                                    @input="changeED(index)"
                                    :disabled="item.disable_ed" 
                                    :disabled-dates="disabledDates"
                                    v-model="item.expired_date" 
                                    placeholder="Select Date" 
                                    required 
                                    :cleared="() => {
                                        item.expired_date = null
                                    }">
                                </datepicker> -->
                                <flat-pickr
                                    @input="changeED(index)"
                                    v-model="item.expired_date"
                                    :disabled="item.disable_ed"
                                    placeholder="Select Date"
                                    style="width: 100%;"
                                    :config="config">
                                </flat-pickr>
                            </td>
                            <!-- <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :disabled="item.po_item_unit.batch == `None`" v-model="item.batch"
                                    class="w-full" />
                            </td> -->
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="item.batch_external"  class="w-full" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <CustomMSelect :value="item.check_status" :options="checkOptions" label="name"
                                    track-by="name" @selected="(v) => {
                                    item.check_status = v;
                                    item.is_damaged = v.value
                                }"></CustomMSelect>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <div class="flex vx-input-group">
                                    <vs-button @click.stop="removeItemsRow(index)"
                                        v-if="index > 0 && form.lines_dto[index].purchase_order_line_id == form.lines_dto[index - 1].purchase_order_line_id"
                                        size="small" color="danger" icon-pack="feather" icon="icon-trash"
                                        style="margin-right: 5px" title="Remove Row" />
                                    <vs-button @click.stop="addItemsRow(index)"
                                        v-if="index == 0 || form.lines_dto[index].purchase_order_line_id != form.lines_dto[index - 1].purchase_order_line_id"
                                        size="small" color="success" icon-pack="feather" icon="icon-plus"
                                        title="Add Row" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="mt-6 vx-row">
            <!-- <div class="w-full vx-col sm:w-1/1"> -->
                <div style="margin-left: auto; margin-right: auto;">
                    <vs-button class="mb-2 mr-3" @click="handleSubmit(false)">Save</vs-button>
                    <vs-button class="mb-2 mr-3" @click="handleSubmit(true)">Save And Confirm</vs-button>
                </div>
            <!-- </div> -->
        </div>
    </vx-card>
</template>

<script>
import Date from "@/components/Date.vue";
import CustomMSelect from "@/components/CustomMultiSelect.vue";
import vSelect from "vue-select";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import FlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
    components: {
        Date,
        CustomMSelect,
        vSelect,
        Datepicker,
        FlatPickr,
    },
    mounted() {
        this.id = this.$route.params.id;
        this.mode = this.$route.params.mode;
        if (this.id) {
            this.initData();
        } else {
            this.$vs.loading();
            setTimeout(this.$vs.loading.close, 3200);
        }
    },
    data() {
        return {
            config:{
                dateFormat: "Y-m-d",
                allowInput: true,
                minDate: "today"
            },
            dialog: {
                save: false,
            },
            checkOptions: [{
                value: 0,
                name: "Good",
            }, {
                value: 1,
                name: "Damaged",
            }, {
                value: 2,
                name: "Expired",
            }],
            popShow: false,
            disabledDates: {
                to: moment().subtract(1, "days").toDate()
            },
            id: null,
            po_type: null,
            attachment: null,
            attachmentIDs: [],
            form: {
                purchase_order_id: null,
                warehouse: null,
                attachment: [],
                dn_date: moment().toDate(),
                purchaseData: null,
                code: null,
                date: null,
                notes: null,
                delivery_order_number: null,
                purchase_date: null,
                lines_dto: [],
            },
        }
    },
    methods: {
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if ((charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        changeED(index){
            if (moment(this.form.lines_dto[index].expired_date).format("YYYYMMDD") != "Invalid date") {
                this.form.lines_dto[index].batch_external = moment(this.form.lines_dto[index].expired_date).format("YYYYMMDD") + this.form.supplier_code
                this.form.lines_dto[index].batch = this.form.lines_dto[index].batch_external + this.form.supplier_code
            } else {
                this.form.lines_dto[index].batch_external = null
                this.form.lines_dto[index].batch = null
            }
        },
        handleUpload() {
            let doUpload = true
            if (this.form.attachment == null) {
                this.form.attachment = []
            }
            
            var formData = new FormData();
            if (this.attachment.length > 0) {
                Array.from(this.attachment).forEach(item => {
                    if (item.size <= 5000000){
                        formData.append("files", item);
                    } else {
                        this.$vs.dialog({
                            type: "confirm",
                            color: "danger",
                            title: `Error`,
                            text: 'Attachment max 5MB',
                        });
                        doUpload = false
                    }
                });
            } else {
                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Error`,
                    text: 'Empty file',
                });
                doUpload = false
            }
            if (doUpload) {
                this.$vs.loading();
                this.$http
                .post(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/receive/upload-file/${this.form.id}`, formData)
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.loading.close();
                        if (this.form.id == 0) {
                            this.attachmentIDs.push(resp.data.id)
                        }
                        this.form.attachment.push(resp.data)
                    } else {
                        this.$vs.loading.close();
                        this.$vs.dialog({
                            type: "confirm",
                            color: "danger",
                            title: `Error`,
                            text: `${resp.code} : ${resp.message}`,
                        });
                    }
                });
            }
            this.attachment = []
            this.$refs.attachment.value = null;
        },
        handleSubmit(confirm = false) {
            if (this.form.delivery_order_number == "") {
                this.$vs.dialog({
                    type: "alert",
                    acceptText: `OK`,
                    color: "danger",
                    title: `Error`,
                    text: `input error : *delivery note number is required`,
                });
                return
            }
            var mustFull = false
            var allItemsZero = true
            var data = Object.assign({}, this.form);
            delete data.code;
            data.status = "0";
            data.lines_dto.forEach((item) => {
                item.quantity = Number(item.quantity)
                if (this.po_type == 4) {                    
                    var delta_qty = (Number(item.po_quantity) - item.quantity)                    
                    if (Number(delta_qty) != 0){
                        mustFull = true
                        return
                    }
                    allItemsZero = false;
                } else {
                    if (item.quantity !== 0) {
                        allItemsZero = false;
                    }
                }
            })
            var po_type = (this.po_type == 3) ? "Bonus" : "Back2Back"
            if (mustFull) {
                this.$vs.dialog({
                    type: "alert",
                    acceptText: `OK`,
                    color: "danger",
                    title: `Error`,
                    text: "PO type "+po_type+" must be received full.",
                });
                return
            }
            if (allItemsZero) {
                this.$vs.dialog({
                    type: "alert",
                    acceptText: `OK`,
                    color: "danger",
                    title: `Error`,
                    text: "Must at least receive one item.",
                });
                return
            }
            data.purchase_receive_lines = data.lines_dto;
            var formData = new FormData();
            formData.append("data", JSON.stringify(data));
            formData.append("attachment_ids", this.attachmentIDs);
            if (this.attachment) {
                this.attachment.forEach(item => {
                    formData.append("files", item);
                });
            }            
            this.$vs.loading();
            this.$http
                .post(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/receive?confirm=${confirm}`, formData)
                .then(resp => {
                    if (resp.code == 200) {
                        // reassign all id
                        var msg = ""
                        this.form.id = resp.data.id
                        resp.data.purchase_receive_lines.forEach((item, index) => {
                            if (!confirm) {
                                this.form.lines_dto[index].id = item.id
                            }
                        })
                        this.initData()
                        this.$vs.loading.close();
                        if (confirm) {                            
                            // if (resp.data.status_putaway == 1) {
                            //     msg = "GR with code "+resp.data.code+" Successfully Created, automatically put away, and set to complete."
                            // } else {
                            //     msg = "GR with code "+resp.data.code+" Successfully Created."
                            // }
                            msg = "GR with code "+resp.data.code+" Successfully Created."
                            this.$vs.dialog({
                                type: "confirm",
                                color: "success",
                                title: `Information`,
                                text: msg,
                                accept: this.handleBack,
                                acceptText: `OK`,
                            });
                        } else {
                            this.$vs.dialog({
                                type: "confirm",
                                color: "success",
                                title: `Updated Successfully`,
                                text: "Updated data is successfully sent to server.",
                                accept: this.handleBack,
                                acceptText: `OK`,
                            });
                        }
                    } else {
                        this.$vs.loading.close();
                        this.$vs.dialog({
                            type: "confirm",
                            color: "danger",
                            title: `Information`,
                            text: `${resp.code} : ${resp.message}`,
                            // accept: this.handleBack,
                            acceptText: `OK`,
                        });
                    }
                });
        },
        addItemsRow(index) {
            var count = 0;
            this.form.lines_dto.forEach((item) => {
                if (this.form.lines_dto[index].purchase_order_line_id == item.purchase_order_line_id) count++;
            })
            // if (count < 3) {
                const newItem = Object.assign({}, this.form.lines_dto[index])
                newItem.id = 0;
                this.form.lines_dto.splice(index + 1, 0, newItem);
            // }
        },
        removeItemsRow(index) {
            if (this.form.lines_dto[index].purchase_order_line_id == this.form.lines_dto[index - 1].purchase_order_line_id) {
                this.form.lines_dto.splice(index, 1)
            }
        },
        handleChangeAttachment(event) {
            this.attachment = event.target.files ? event.target.files : null;
        },
        handleDeleteAttachment(index, id) {
            var files = Array.from(this.form.attachment)
            files.splice(index, 1)
            this.form.attachment = files;
            if (id) {
                this.deleteFile(id);
            }
        },
        handleDeleteFile(index) {
            var files = Array.from(this.attachment)
            files.splice(index, 1)
            this.attachment = files;
        },
        deleteFile(id) {
            this.$vs.loading();
            this.$http.delete(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/receive/${this.form.id}/attachment/${id}`)
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        // this.form.attachment = resp.data;
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                });
        },
        handleBack() {
            this.$vs.loading();
            this.$router.push({
                name: this.$store.state.inbound.purchaseOrder.baseRouterName,
            });
            this.$vs.loading.close();
        },
        initData() {
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/receive/create/purchase-order/${this.id}`)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.$vs.loading.close();
                        this.po_type = resp.data.po_type_id
                        this.form = resp.data;
                        if (this.form.id == 0) {
                            resp.data.dn_date = moment().toDate()
                            // this.form.date = resp.data.eta
                            this.form.date = moment().toDate()
                            if (resp.data.notes != "") {
                                this.form.notes = `${resp.data.notes}`
                            } else {
                                if (resp.data.po_notes != "") {
                                    this.form.notes = `PO notes : ${resp.data.po_notes}`
                                }
                            }
                        }                                        
                        this.form.lines_dto.forEach((item, idx) => {
                            if (item.id == 0) {
                                // item.quantity = Number(item.po_quantity);
                                item.quantity = Number(0);
                                item.item_unit = Object.assign({}, item.po_item_unit);
                                item.item_unit_id = item.po_item_unit_id
                            }
                            if (item.expired_date == "") { // jika data awal, gr belom di save
                                // jika no, + 1 tahun
                                // jika yes, blank
                                item.expired_date = (item.po_item_unit.is_expired == "0") ? moment().add(1, 'years').toDate() : item.expired_date;
                            }
                            this.form.lines_dto[idx].disable_ed = false
                            // this.form.lines_dto[idx].disable_ed = true
                            // if (item.po_item_unit.is_expired == "1") {
                            //     this.form.lines_dto[idx].disable_ed = false
                            // }
                            item.check_status = this.checkOptions[item.is_damaged]
                            item.supplier_code = this.form.supplier_code
                            if (item.expired_date != "" && item.batch_external == "") {
                                item.batch_external = moment(item.expired_date).format("YYYYMMDD") + this.form.supplier_code
                            }
                            if (item.batch == "") {
                                if (item.batch_external != "") {
                                    item.batch = item.batch_external + this.form.supplier_code
                                }
                            }
                        })
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
    },
    // watch: {
    //     "form.attachment": function (val) {
    //         console.log("form attachment berubah")
    //         console.log(this.form.attachment)
    //         if (this.form.attachment == null) {
    //             this.form.attachment = []
    //         }
    //     }
    // }
}
</script>

<style>
td.td.vs-table--td {
    vertical-align: text-top !important;
}

.vs-input--input:disabled,
input:disabled {
    pointer-events: auto !important;
}

.upload {
    display: flex;
}

.upload button.up {
    margin: 0px !important;
}

.upload button.btnx {
    margin-left: 10px !important;
    border-radius: 5px 0px 0px 5px;
}

.upload button.btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, .2);
}

.vs-dialog-warning .vs-dialog footer .vs-button-primary{
    display: none;
}

.vs-dialog-danger .vs-dialog footer .vs-button-primary{
    display: none;
}

.vs-dialog-success .vs-dialog footer .vs-button-primary{
    display: none;
}

</style>